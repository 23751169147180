.t-\[20\] {
    font-size: 20px
}
.t-\[16\] {
    font-size: 16px
}
.pl-\[10\] {
    padding-left: 10px
}
.ml-\[auto\] {
    margin-left: auto
}
.mr-\[0\] {
    margin-right: 0px
}
.w-\[1200\] {
    width: 1200px
}
.mx-\[auto\] {
    margin-left: auto;
    margin-right: auto
}
.pt-\[8\] {
    padding-top: 8px
}
.pb-\[30\] {
    padding-bottom: 30px
}
.t-\[30\] {
    font-size: 30px
}
.pt-\[6\] {
    padding-top: 6px
}
.flex {
    display: flex
}
.justify-between {
    justify-content: space-between
}
.h-\[516\] {
    height: 516px
}
.m-\[auto\] {
    margin: auto
}
.rounded-\[4\] {
    border-radius: 4px
}
.t-\[24\] {
    font-size: 24px
}
.t-\[\#192530\] {
    color: #192530
}
.pt-\[60\] {
    padding-top: 60px
}
.w-\[100\%\] {
    width: 100%
}
.min-w-\[1440\] {
    min-width: 1440px
}
.h-\[100vh\] {
    height: 100vh
}
.min-h-\[750\] {
    min-height: 750px
}
.bg-\[\#F7F7F9\] {
    background-color: #F7F7F9
}
.w-\[468\] {
    width: 468px
}
.bg-\[\#FFFFFF\] {
    background-color: #FFFFFF
}
.px-\[72\] {
    padding-left: 72px;
    padding-right: 72px
}
.pb-\[20\] {
    padding-bottom: 20px
}
.\!px-\[16\] {
    padding-left: 16px !important;
    padding-right: 16px !important
}
.\!py-\[8\] {
    padding-top: 8px !important;
    padding-bottom: 8px !important
}
.\!border-\[0\] {
    border-width: 0px !important
}
.relative {
    position: relative
}
.justify-center {
    justify-content: center
}
.items-center {
    align-items: center
}
.overflow-hidden {
    overflow: hidden
}
.text-center {
    text-align: center
}
.flex-col {
    flex-direction: column
}
.mt-\[48\] {
    margin-top: 48px
}
.min-w-\[1200\] {
    min-width: 1200px
}
.w-\[400\] {
    width: 400px
}
.leading-\[28\] {
    line-height: 28px
}
.tracking-\[4\] {
    letter-spacing: 4px
}
.mb-\[24\] {
    margin-bottom: 24px
}
.pb-\[16\] {
    padding-bottom: 16px
}
.t-\[12\] {
    font-size: 12px
}
.t-\[\#a1a1a1\] {
    color: #a1a1a1
}
.leading-\[20\] {
    line-height: 20px
}
.right-\[0\] {
    right: 0px
}
.\!w-\[164\] {
    width: 164px !important
}
.\!w-\[226\] {
    width: 226px !important
}
.bg-\[\#F0F2F5\] {
    background-color: #F0F2F5
}
.w-\[246\] {
    width: 246px
}
.h-\[22\] {
    height: 22px
}
.leading-\[22\] {
    line-height: 22px
}
.top-\[50\] {
    top: 50px
}
.t-\[\#303133\] {
    color: #303133
}
.absolute {
    position: absolute
}
.t-\[18\] {
    font-size: 18px
}
.mr-\[20\] {
    margin-right: 20px
}
.p-\[20\] {
    padding: 20px
}
.pb-\[10\] {
    padding-bottom: 10px
}
.text-left {
    text-align: left
}
.t-\[\#B7B9BE\] {
    color: #B7B9BE
}
.pb-\[4\] {
    padding-bottom: 4px
}
.border-b-\[1\] {
    border-bottom-width: 1px
}
.border-\[\#D5DAE5\] {
    border-color: #D5DAE5
}
.t-\[\#888A90\] {
    color: #888A90
}
.max-w-\[1200\] {
    max-width: 1200px
}
.t-\[\#909399\] {
    color: #909399
}
.ml-\[6\] {
    margin-left: 6px
}
.mb-\[4\] {
    margin-bottom: 4px
}
.t-\[var\(--ant-primary-color\)\] {
    color: var(--ant-primary-color)
}
.border-solid {
    border-style: solid
}
.vertical-middle {
    vertical-align: middle
}
.shrink {
    flex-shrink: 1
}
.whitespace-nowrap {
    white-space: nowrap
}
.underline {
    text-decoration: underline
}
.mt-\[6\] {
    margin-top: 6px
}
.text-right {
    text-align: right
}
.flex-wrap {
    flex-wrap: wrap
}
.t-\[\#FFFFFF\] {
    color: #FFFFFF
}
.bg-\[\#303133\] {
    background-color: #303133
}
.rounded-\[2\] {
    border-radius: 2px
}
.top-\[60\] {
    top: 60px
}
.left-\[0\] {
    left: 0px
}
.z-\[10\] {
    z-index: 10
}
.t-\[\#C0C4CC\] {
    color: #C0C4CC
}
.py-\[6\] {
    padding-top: 6px;
    padding-bottom: 6px
}
.px-\[13\] {
    padding-left: 13px;
    padding-right: 13px
}
.leading-\[18\] {
    line-height: 18px
}
.top-\[-10\] {
    top: -10px
}
.left-\[10\] {
    left: 10px
}
.t-\[13\] {
    font-size: 13px
}
.t-\[10\] {
    font-size: 10px
}
.pt-\[10\] {
    padding-top: 10px
}
.ml-\[20\] {
    margin-left: 20px
}
.pb-\[14\] {
    padding-bottom: 14px
}
.pt-\[20\] {
    padding-top: 20px
}
.h-\[64\] {
    height: 64px
}
.border-\[\#DDDEE0\] {
    border-color: #DDDEE0
}
.h-\[100\%\] {
    height: 100%
}
.pl-\[2\] {
    padding-left: 2px
}
.\!t-\[12\] {
    font-size: 12px !important
}
.ml-\[36\] {
    margin-left: 36px
}
.cursor-pointer {
    cursor: pointer
}
.px-\[30\] {
    padding-left: 30px;
    padding-right: 30px
}
.mt-\[12\] {
    margin-top: 12px
}
.pt-\[30\] {
    padding-top: 30px
}
.pr-\[16\] {
    padding-right: 16px
}
.pl-\[16\] {
    padding-left: 16px
}
.w-\[100vw\] {
    width: 100vw
}
.bg-\[\#F7F8FC\] {
    background-color: #F7F8FC
}
.px-\[10\] {
    padding-left: 10px;
    padding-right: 10px
}
.pl-\[6\] {
    padding-left: 6px
}
.pb-\[26\] {
    padding-bottom: 26px
}
.t-\[14\] {
    font-size: 14px
}
.pt-\[18\] {
    padding-top: 18px
}
.t-\[26\] {
    font-size: 26px
}
.pt-\[4\] {
    padding-top: 4px
}
.font-600 {
    font-weight: 600
}
.pb-\[22\] {
    padding-bottom: 22px
}
.leading-\[14\] {
    line-height: 14px
}
.mb-\[8\] {
    margin-bottom: 8px
}
.t-\[\#B1B1B1\] {
    color: #B1B1B1
}
.pl-\[26\] {
    padding-left: 26px
}
.pr-\[26\] {
    padding-right: 26px
}
.pb-\[12\] {
    padding-bottom: 12px
}
.pr-\[4\] {
    padding-right: 4px
}
.pt-\[64\] {
    padding-top: 64px
}
.min-h-\[600\] {
    min-height: 600px
}
.pt-\[48\] {
    padding-top: 48px
}
.mb-\[0\] {
    margin-bottom: 0px
}
.ml-\[10\] {
    margin-left: 10px
}
.inline {
    display: inline
}
.pt-\[40\] {
    padding-top: 40px
}
.pb-\[40\] {
    padding-bottom: 40px
}
.p-\[26\] {
    padding: 26px
}
.pt-\[24\] {
    padding-top: 24px
}
.pl-\[4\] {
    padding-left: 4px
}
.mr-\[10\] {
    margin-right: 10px
}
.pl-\[20\] {
    padding-left: 20px
}
.p-\[12\] {
    padding: 12px
}
.pt-\[16\] {
    padding-top: 16px
}
.pl-\[8\] {
    padding-left: 8px
}
.font-700 {
    font-weight: 700
}
.pt-\[28\] {
    padding-top: 28px
}
.pb-\[28\] {
    padding-bottom: 28px
}
.pb-\[48\] {
    padding-bottom: 48px
}
.float-left {
    float: left
}
.w-\[150\] {
    width: 150px
}
.mt-\[32\] {
    margin-top: 32px
}
.leading-\[26\] {
    line-height: 26px
}
.mr-\[4\] {
    margin-right: 4px
}
.t-\[\#367BF0\] {
    color: #367BF0
}
.\!w-\[160\] {
    width: 160px !important
}
.min-h-\[80vh\] {
    min-height: 80vh
}
.border-b-\[3\] {
    border-bottom-width: 3px
}
.border-\[\#f7f8fc\] {
    border-color: #f7f8fc
}
.p-\[30\] {
    padding: 30px
}
.t-\[\#000000\] {
    color: #000000
}
.w-\[60\%\] {
    width: 60%
}
.h-\[134\] {
    height: 134px
}
.rounded-\[6\] {
    border-radius: 6px
}
.mt-\[20\] {
    margin-top: 20px
}
.bg-\[\#E5EDFF\] {
    background-color: #E5EDFF
}
.px-\[32\] {
    padding-left: 32px;
    padding-right: 32px
}
.w-\[62\] {
    width: 62px
}
.h-\[62\] {
    height: 62px
}
.ml-\[18\] {
    margin-left: 18px
}
.t-\[var\(--main-color\)\] {
    color: var(--main-color)
}
.t-\[\#777777\] {
    color: #777777
}
.w-\[46\] {
    width: 46px
}
.h-\[46\] {
    height: 46px
}
.rounded-\[50\%\] {
    border-radius: 50%
}
.leading-\[46\] {
    line-height: 46px
}
.t-\[\#F53F3F\] {
    color: #F53F3F
}
.font-900 {
    font-weight: 900
}
.block {
    display: block
}
.flex-1 {
    flex: 1 1 0%
}
.w-\[210\] {
    width: 210px
}
.right-\[20\] {
    right: 20px
}
.top-\[20\] {
    top: 20px
}
.justify-end {
    justify-content: flex-end
}
.h-\[92vh\] {
    height: 92vh
}
.pb-\[5\] {
    padding-bottom: 5px
}
.t-\[\#000\] {
    color: #000
}
.w-\[180\] {
    width: 180px
}
.bg-\[\#fff\] {
    background-color: #fff
}
.pl-\[13\] {
    padding-left: 13px
}
.pb-\[2\] {
    padding-bottom: 2px
}
.t-\[theme\] {
    color: #0048FF
}
.py-\[28\] {
    padding-top: 28px;
    padding-bottom: 28px
}
.w-\[140\] {
    width: 140px
}
.h-\[140\] {
    height: 140px
}
.border-\[theme\] {
    border-color: #0048FF
}
.w-\[200\] {
    width: 200px
}
.t-\[\#788593\] {
    color: #788593
}
.items-start {
    align-items: flex-start
}
.min-h-\[800\] {
    min-height: 800px
}
.t-\[\#BEBEBE\] {
    color: #BEBEBE
}
.my-\[130\] {
    margin-top: 130px;
    margin-bottom: 130px
}
.mt-\[28\] {
    margin-top: 28px
}
.t-\[\#9A9A9A\] {
    color: #9A9A9A
}