.opers {
  row-gap: 10px;
  button + button {
    margin-left: 10px;
  }
}
.suffixBox {
  font-size: 12px;
  box-sizing: border-box;
  padding: 2px 0px;
  color: #909399;
}

.prefixTitle {
  position: relative;
  padding-left: 12px;
  margin: 14px 0;
  font-size: 14px;
  color: #303133;
  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 0;
    background-color: var(--main-color);
  }
}